<template>
	<div class="section">
		<div class="container">
			<div class="section__wrap">
				<div class="section__cnt">
					<div class="section__head section__head--table">
						<div class="title title--section">{{inDashboard ? 'Active orders' : 'Orders list.'}}</div>

						<div class="section__options" v-if="!inDashboard">
							<div class="section__option">
								<form-select v-model="booster"
										class="has-sm _m-0"
										:options="boosters"
										name="client"
										option_label="name"
										label="booster"></form-select>
							</div>
							<div class="section__option">
								<form-select v-model="client"
										class="has-sm _m-0"
										:options="clients"
										name="client"
										option_label="email"
										label="Client"></form-select>
							</div>
							<div class="section__option section__option--select" v-if="accessRoot">
								<form-select-tags v-model="status"
										class="has-sm _m-0"
										:options="formattedStatuses"
										name="status"
										option_label="title"
										label="Status"></form-select-tags>
							</div>
						</div>
					</div>

					<div class="section__main">
						<div class="order">
							<ul class="order__list" v-if="orders.length">
								<li class="order__item" v-for="order in orders" :key="order.id">
									<order-card :order="order"></order-card >
								</li>
							</ul>

							<div class="order__empty" v-else>No orders...</div>

							<div class="order__footer" v-if="hasShowPagination">
								<vue-pagination
										:pagination="pagination"
										@changeLimit="onPaginationChangeLimit"
										@changePage="onPaginationChangePage"></vue-pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';
	import OrderCard from './OrderCard'
	import VuePagination from '@components/VuePagination';

	export default {
		name: 'orders-index',
		components:{
			OrderCard,
			VuePagination
		},
		props: ['inDashboard'],

		data(){
			return{
				sort_price: null,
				sort_date: null,
				sort_games: null,

				hasLoadData: false
			}
		},

		computed:{
			...mapState({
				user: state => state.auth.user,
				orders: state => state.orders.list,
				boosters: state => state.orders.boosters,
				pagination: state => state.orders.listPagination,
				statuses: state => state.orders.statuses,
				clients: state => state.orders.clients,
				listFilter: state => state.orders.listFilter
			}),
			...mapGetters({
				accessRoot: 'accessRoot',
				accessAdmin: 'accessAdmin',
				accessBooster: 'accessBooster'
			}),

			formattedStatuses(){
				return 	$fn.map(this.statuses, (status, key) => {
					return{
						id: key,
						title: status
					}
				})
			},

			status:{
				get(){
					return $fn.map(this.listFilter['statuses'], el => {
						return $fn.find(this.formattedStatuses, ['id', el])
					});
				},
				set(val){
					this.SET_FILTER_LIST({key: 'page', val: 1});
					this.SET_FILTER_LIST({key: 'statuses', val: $fn.map(val, el =>{
						return el.id
					})});

					this.getList();
				}
			},

			client:{
				get(){
					return $fn.find(this.clients, ['id', +this.listFilter['client_id']])
				},
				set(val){
					this.SET_FILTER_LIST({key: 'page', val: 1});
					this.SET_FILTER_LIST({key: 'client_id', val: val ? val.id : null});
					this.getList();
				}
			},

			booster:{
				get(){
					return $fn.find(this.clients, ['id', +this.listFilter['booster_id']])
				},
				set(val){
					this.SET_FILTER_LIST({key: 'page', val: 1});
					this.SET_FILTER_LIST({key: 'booster_id', val: val ? val.id : null});
					this.getList();
				}
			},

			hasShowPagination(){
				if (!this.hasLoadData || !this.pagination) return false;
				return this.pagination.total > this.orders.length;
			},
		},

		created(){
			if(!this.accessBooster) this.initCommonData();
			this.getList(this.accessBooster ? {booster_id: this.user.id} : {})
		},

		methods:{
			...mapMutations({
				SET_FILTER_LIST: 'orders/SET_FILTER_LIST'
			}),
			...mapActions({
				GET_ORDERS: 'orders/GET_LIST',
				GET_STATUSES: 'orders/GET_STATUSES',
				GET_CLIENTS: 'orders/GET_CLIENTS',
				GET_BOOSTERS: 'orders/GET_BOOSTERS'
			}),

			getList(filters){
				this.GET_ORDERS({
					filters: filters

				}).then(() => {
					this.hasLoadData = true;
				})
			},

			initCommonData () {
				return Promise.all([
					this.GET_STATUSES(),
					this.GET_CLIENTS(),
					this.GET_BOOSTERS()
				])
			},

			onPaginationChangeLimit(limit){
				this.SET_FILTER_LIST({key: 'limit', val: limit});
				this.getList();
			},

			onPaginationChangePage(page){
				this.SET_FILTER_LIST({key: 'page', val: page});
				this.getList();
			},
		}
	};
</script>

<style scoped lang=scss>
	.section__head--table{
		margin-bottom: 10px;
	}
	.section{
		&__options {
			margin-bottom: 0;
		}
		&__option{
			&--select{
				width: 350px;
			}
		}
	}
	.container{
		max-width: 920px;
		width: 100%;
	}

	.order{
		&__list{
			width: 900px;
			margin: 0 auto;
		}
		&__item{
			margin-bottom: 20px;
		}
	}
</style>
