<template>
	<div class="chat">
        <div class="chat__mainWrap">
            <div class="chat__main" :style="{'background-image': `url(/img/pattern_menu1.webp)`}">
                <div class="chat__container" ref="chat">
                    <ul class="chat__list" ref="chatList" v-if="list.length">
                        <li class="chat__item" v-for="item in list">
                            <div class="chat__card" :class="{'has-you': item.sender.role === ROLE_CLIENT}">
                                <div class="chat__ava">
                                    <img :src="require('@img/ava/ava1.png')" v-if="item.sender.role === ROLE_CLIENT" alt="">
                                    <img :src="require('@img/ava/ava2.png')" v-else-if="item.sender.role === ROLE_BOOSTER" alt="">
                                    <img :src="require('@img/faq_icon1.png')" alt="" v-else>
                                    <span>
										<template v-if="item.sender.role === ROLE_CLIENT">You</template>
										<template v-else-if="item.sender.role === ROLE_BOOSTER">Booster</template>
										<template v-else>Admin</template>
									</span>
                                </div>
                                <div class="chat__text">{{item.message}}</div>
                            </div>
                        </li>
                    </ul>
                    <div class="chat__empty" v-if="!list.length">Chat with client</div>
                </div>
            </div>
        </div>

		<div class="chat__sub">
			<textarea class="chat__area" v-model="message" placeholder="Write your message..."></textarea>
			<!--<div class="chat__file">-->
				<!--<v-icon name="paperclip"></v-icon>-->
			<!--</div>-->
			<div class="chat__send" @click="onSend">
				<v-icon name="paper-plane"></v-icon>
				<span>send</span>
			</div>
		</div>

        <div class="chat__radio" :class="{'has-disabled': !hasBooster}">
            <form-radio-group v-model="staffType" name="staff" :data="staffOptions"></form-radio-group>
        </div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	export default {
		name: 'calc-chat',
		props:['order'],
		data(){
			return{
                staffType: 'admin',

				message: null,
				hasLoadData: false,

				intervalTimer: null,

                staffOptions:[
                    {
                        name: 'Admin',
                        value: 'admin'
                    },
                    {
                        name: 'Booster',
                        value: 'booster'
                    }
                ]
			}
		},

		computed:{
			...mapState({
				ROLE_CLIENT: state => state.orders.chat.ROLE_CLIENT,
				ROLE_BOOSTER: state => state.orders.chat.ROLE_BOOSTER,

                order: state => state.orders.item,
				list: state => state.orders.chat.list,
				loadingGetList: state => state.orders.chat.loadingGetList,
				loadingSaveItem: state => state.orders.chat.loadingSaveItem
			}),

            hasBooster(){
                return this.order.booster
            }
		},

		created(){
			this.getList('init');

			this.intervalTimer = setInterval(() => { //@TODO need update to socket
				this.getList();
			},10000);
		},

		beforeDestroy(){
			clearInterval(this.intervalTimer)
		},

		methods:{
			...mapActions({
				GET_LIST: 'orders/chat/GET_LIST',
				SAVE_ITEM : 'orders/chat/SAVE_ITEM'
			}),

			getList: $fn.debounce(function (type) {
                let lengthBefore = this.list.length;

				this.GET_LIST(this.order.id).then(() => {
                    let lengthAfter = this.list.length;
					this.hasLoadData = true;

                    if(lengthAfter > lengthBefore) this.scrollChat();
				});

				if(type === 'init') this.scrollChat();
			}, 300),

			onSend(){
				this.SAVE_ITEM({
					orderId: this.order.id,
					formData:{
						message: this.message,
                        is_from_booster: this.staffType === 'booster'
					}

				}).then(() => {
					this.message = null;
					this.scrollChat();
				})
			},

			scrollChat(){
                let element = this.$refs['chatList'];

                setTimeout(() => {
                    element.scrollTop = element.scrollHeight
                },200)

			}
		}
	};
</script>

<style scoped lang=scss>
.chat{
    height: 515px;
    width: 100%;
    border-radius: 50px;
    background-color: #DCD2E4;
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    /*background-position: center center;*/
    background-image: none;
    &__mainWrap{
        height: 400px;
        padding: 40px 10px 20px 10px;
        position: relative;
    }
    &__main{
        height: 100%;
        &:before{
            content: '';
            display: block;
            height: 40px;
            position: absolute;
            left: 10px;
            right: 25px;
            top: 30px;
            background: -moz-linear-gradient(top,  rgba(220, 210, 228, 0) 0%, rgba(220, 210, 228,0) 100%);
            background: -webkit-linear-gradient(top,  rgb(220, 210, 228) 0%,rgba(220, 210, 228,0) 100%);
            background: linear-gradient(to bottom,  rgb(220, 210, 228) 0%,rgba(220, 210, 228,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#fff',GradientType=0 );
        }
        &:after{
            content: '';
            display: block;
            height: 40px;
            position: absolute;
            left: 10px;
            right: 25px;
            bottom: 20px;
            background: -moz-linear-gradient(top,  rgba(220, 210, 228, 0) 0%, rgb(220, 210, 228) 100%);
            background: -webkit-linear-gradient(top,  rgba(220, 210, 228, 0) 0%, rgb(220, 210, 228) 100%);
            background: linear-gradient(to bottom,  rgba(220, 210, 228, 0) 0%, rgb(220, 210, 228) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00dcd2e4', endColorstr='#dcd2e4',GradientType=0 );

        }
    }
    &__sub{
        display: flex;
        align-items: center;
        padding: 0 10px;
        margin: 0 20px;
        border-radius: 25px;
        background: #4a3a5f5c;
    }
    &__wraparea{
        background: #eee;
        flex-grow: 1;
        border-radius: 50px;
        padding: 20px 0;
        position: relative;
    }
    &__file{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        padding: 5px;
        cursor: pointer;
        &:hover{
            svg{
                fill: $c_alt;
            }
        }
        svg{
            width: 25px;
            height: 25px;
            fill: $c_text_light;
        }
    }
    &__send{
        display: flex;
        align-items: center;
        border-radius: 30px;
        color: $c_alt;
        padding: 5px 10px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover{
            span{
                text-decoration: underline;
            }
        }
        svg{
            fill: $c_alt;
            margin-right: 5px;
        }
        span{
            color: $c_alt;
            font-family: $f_alt;
            font-size: 20px;
            font-weight: normal;
        }
    }
    &__area{
        display: block;
        width: 100%;
        height: 100%;
        resize: none;
        padding: 10px 85px 10px 30px;
        border: none;
        background: transparent;
    }
    &__container{
        height: 100%;
        width: 100%;
        overflow: auto;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &::-webkit-scrollbar {
            @media(min-width: $def) {
                width: 12px;
                border-radius: 5px;
                background-color: #F5F5F5;
            }
        }

        &::-webkit-scrollbar-track {
            @media(min-width: $def) {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 5px;
                background-color: #F5F5F5;
            }
        }

        &::-webkit-scrollbar-thumb {
            @media(min-width: $def) {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background-color: #9f9f9f;
            }
        }
    }
    &__list{
        width: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
            @media(min-width: $def) {
                width: 12px;
                border-radius: 5px;
                background-color: #F5F5F5;
            }
        }

        &::-webkit-scrollbar-track {
            @media(min-width: $def) {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 5px;
                background-color: #F5F5F5;
            }
        }

        &::-webkit-scrollbar-thumb {
            @media(min-width: $def) {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background-color: #9f9f9f;
            }
        }
    }
    &__item{
        margin-bottom: 20px;
        padding: 0 10px;
    }
    &__card{
        width: 88%;
        display: flex;
        align-items: flex-end;
        &.has-you{
            margin-left: auto;
            justify-content: flex-end;
            .chat__text{
                order: -1;
                background: rgba(238, 228, 246, 0.62);
                border-radius: 50px 50px 0 50px;
            }
            .chat__ava{
                margin-right: 0;
                margin-left: 10px;
            }
        }
    }
    &__ava{
        width: 40px;
        margin-right: 10px;
        flex-shrink: 0;
        line-height: 0;
        text-align: center;
        span{
            padding-top: 2px;
            display: block;
            line-height: 1.2em;
            font-size: 12px;
            color: $c_text_light;
        }
    }
    &__text{
        padding: 20px;
        border-radius: 50px 50px 50px 0;
        background: #f3e9fb;
    }
    &__empty{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-family: $f_alt;
        color: $c_border;
        font-size: 42px;
    }
    &__radio{
        display: flex;
        padding: 20px 30px;
    }
}
</style>
